<!-- 用户编辑弹窗 -->
<template>
  <a-modal :width="1000" :visible="visible" title="退仓审批" :body-style="{ paddingTop: 0 }" :confirm-loading="loading"
    @update:visible="updateVisible">
    <template #footer>
      <a-space>
        <a-button @click="handleCancle"> 取消 </a-button>
        <a-button type="primary" @click="agreeOk" v-if="isApprove">{{isOwner?'提交':'通过'}}</a-button>
        <a-button type="danger" @click="rejectOk" v-if="isApprove && !isOwner">
          驳回
        </a-button>
        <a-button type="danger" @click="delApprove" v-if="isApprove && isOwner">
          删除
        </a-button>
      </a-space>
    </template>
    <a-form class="ud-form-detail" ref="form" :model="form" :rules="rules">
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title">申请信息</a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="申请人:" name="applicantName">
            {{ form.applicantName }}
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24" :xs="24">
          <a-form-item label="申请人单位:" name="unitName">
            {{ form.unitName }}
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="12" :xs="24">
          <a-form-item label="申请人部门:" name="departmentName">
            {{ form.departmentName }}
          </a-form-item>
        </a-col>
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="备注:" name="remark">
            {{ form.remark }}
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title">资产列表</a-form-item>
        </a-col>
      </a-row>
      <div>
        <div class="ud-text-right" v-if="isOwner && isApprove">
          <a-button @click="addAssets()">选择资产</a-button>
        </div>
      </div>
      <ud-pro-table ref="table" row-key="assetsId" :datasource="formData" :columns="columns"
        :scroll="{ y: '200px', x: 'max-content' }" :toolbar="false">
        <template #action="{ index }" v-if="isOwner && isApprove">
          <a-space>
            <a @click="remove(index)">移除</a>
          </a-space>
        </template>
      </ud-pro-table>
      <a-divider />
      <a-row>
        <a-col>
          <a-form-item class="ant-descriptions-title">审批信息</a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :lg="12" :md="24" :sm="24" :xs="24" class="ant-col-sty">
          <a-card :bordered="false" :body-style="{ padding: 0 }">
            <a-timeline>
              <a-timeline-item v-for="(item, index) in formNodeList" :key="index">
                <div v-if="item.flowNodeType === 'start'">
                  <div>{{ item.createTime }} 发起申请</div>
                  <div>{{ item.approverName }}</div>
                </div>
                <div v-if="item.flowNodeType === 'node'">
                  <div v-if="item.approveState === '已审批'">
                    {{ item.createTime }} 审批
                  </div>
                  <div v-else>审批人</div>
                  <div>{{ item.approverName }} ({{ item.approveState }})</div>
                  <div v-if="item.approveState === '已审批'">
                    结果：{{ item.approveResult }})
                  </div>
                  <div v-if="item.approveState === '已审批'">
                    {{ item.approveComments }}
                  </div>
                </div>
                <div v-if="item.flowNodeType === 'end'">
                  <div v-if="item.approveState === '已审批'">
                    {{ item.createTime }} 审批
                  </div>
                  <div v-else>审批人</div>
                  <div>{{ item.approverName }} ({{ item.approveState }})</div>
                  <div v-if="item.approveState === '已审批'">
                    结果：{{ item.approveResult }}
                  </div>
                  <div v-if="item.approveState === '已审批'">
                    {{ item.approveComments }}
                  </div>
                </div>
              </a-timeline-item>
            </a-timeline>
          </a-card>
        </a-col>
        <a-col :lg="12" :md="24" :sm="24" :xs="24" v-if="isApprove">
          <a-card :bordered="false" :body-style="{ padding: 0 }">
            <a-col :md="24" :sm="24" :xs="24">
              <a-form-item label="处理人:" name="approverName" :labelCol="{ style: 'width: 100px' }">
                {{ form.approverName }}
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24" :xs="24">
              <a-form-item label="处理时间:" name="approveTime" :labelCol="{ style: 'width: 100px' }">
                {{ form.approveTime }}
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24" :xs="24">
              <a-form-item label="意见:" name="approveResult" :labelCol="{ style: 'width: 100px' }">
                <a-textarea v-model:value="form.approveComments" placeholder="请输入备注或反馈信息" :rows="4" />
              </a-form-item>
            </a-col>
          </a-card>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <assetsInfoSelected v-model:visible="showSelected" @done="setFormData" :category-data="categoryData"
    :organization-list="organizationList" :filter="filter" />
</template>

<script>
import * as formInfoApi from '@/api/ams/formInfo'
import assetsInfoSelected from '../assets/selected.vue'
import moment from 'moment'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue'
export default {
  name: 'withdrawalApplication',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 全部机构
    organizationList: Array,
    // 分类
    categoryData: Array
  },
  components: {
    assetsInfoSelected
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data, {
        approverName: this.$store.state.user.user.username,
        approveTime: moment().format('YYYY-MM-DD HH:mm:ss')
      }),
      formData: [],
      filter: { state: 'idle' },
      columns: [
        {
          width: 48,
          align: 'center',
          fixed: 'left',
          customRender: ({ index }) => index + 1
        },
        {
          title: '资产编号',
          width: 100,
          align: 'center',
          fixed: 'left',
          dataIndex: 'assetsCode',
          sorter: true
        },
        {
          title: '资产名称',
          width: 100,
          align: 'center',
          dataIndex: 'assetsName',
          sorter: true
        },
        {
          title: '分类编码',
          width: 100,
          align: 'center',
          dataIndex: 'categoryCode',
          sorter: true
        },
        {
          title: '品牌',
          align: 'center',
          dataIndex: 'brand',
          sorter: true
        },
        {
          title: '型号',
          align: 'center',
          dataIndex: 'model',
          sorter: true
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'count',
          sorter: true
        },
        {
          title: '单价',
          align: 'center',
          dataIndex: 'unitPrice',
          sorter: true
        },
        {
          title: '金额',
          align: 'center',
          dataIndex: 'total',
          sorter: true
        },
        {
          title: '取得时间',
          align: 'center',
          dataIndex: 'buyDate',
          sorter: true,
          customRender: ({ text }) =>
            this.$util.toDateString(text, 'yyyy-MM-dd')
        },
        {
          title: '所属单位',
          align: 'center',
          dataIndex: 'affiliatedUnitName',
          sorter: true
        },
        {
          title: '公物仓',
          align: 'center',
          dataIndex: 'warehouseName',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: 100,
          align: 'center',
          fixed: 'right',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表单验证规则
      rules: {},
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 审批记录
      formNodeList: [],
      // 是否能审批
      isApprove: false,
      isOwner: false,
      userInfo: Object.assign({}, this.$store.state.user.user),
      // 是否能选择资产
      showSelected: false,
      affiliatedUnitL: null
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        this.form.approverName = this.$store.state.user.user.username
        this.form.approveTime = moment().format('YYYY-MM-DD HH:mm:ss')
        this.form.formId = this.form.id
        this.where.formId = this.form.id
        this.isOwner = this.form.applicant === this.userInfo.userId
        this.getApprove()
        this.getFormNodes()
        this.getFlowNodes()
        this.getAssetsData()
        this.isUpdate = true
        this.$nextTick(() => {
          this.reload()
        })
      } else {
        this.form = {}
        this.isUpdate = false
      }
    }
  },
  methods: {
    getAssetsData() {
      formInfoApi
        .assets({
          ...this.where,
          page: 1,
          limit: 1000
        })
        .then((res) => {
          if (res.code === 0) {
            this.formData = res.data
            this.affiliatedUnit = this.formData[0].affiliatedUnit
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // datasource(option, callback) {
    //   formInfoApi
    //     .assets({
    //       ...this.where,
    //       page: option.page,
    //       limit: option.limit
    //     })
    //     .then((res) => {
    //       if (res.code === 0) {
    //         callback(res.data, res.count)
    //       } else {
    //         callback(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       callback(e.message)
    //     })
    // },
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload({ page: 1 })
    },
    handleCancle() {
      this.updateVisible(false)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 判断是否可审批
    getApprove() {
      formInfoApi
        .isApprove(this.form)
        .then((res) => {
          if (res.code === 0) {
            if (res.data === true) {
              this.isApprove = true
            } else {
              this.isApprove = false
            }
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
        .catch(() => { })
    },
    addAssets() {
      // this.restAssetsWhere(this.form.formId)
      const flowId = this.form.flowId
      const formId = this.form.formId
      this.restAssetsWhere(flowId, formId)
      this.showSelected = true
    },
    // 资产条件
    restAssetsWhere(flowId, formId) {
      const that = this
      if (flowId === 2) {
        that.filter = {
          state: 'idle',
          unitId: that.affiliatedUnit,
          formId
        }
      } else if (flowId === 3) {
        that.filter = {
          state: 'idle',
          unitId: that.affiliatedUnit,
          formId
        }
      } else if (flowId === 4) {
        that.filter = {
          state: 'use',
          userId: that.userInfo.userId,
          formId
        }
      } else if (flowId === 5) {
        that.filter = {
          state: 'idle',
          unitId: that.affiliatedUnit,
          formId
        }
      } else if (flowId === 1) {
        that.filter = {
          state: 'warehousing',
          userId: that.userInfo.userId,
          formId
        }
      }
    },
    // 删除申请
    delApprove() {
      const that = this
      Modal.confirm({
        title: '删除',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, '是否删除此次申请?'),
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          formInfoApi.deleteById(that.form.formId).then(res => {
            if (res.code === 0) {
              that.updateVisible(false)
              that.$emit('done')
            } else {
              that.$message.error(res.msg)
            }
          }).catch(e => {
            that.$message.error(e.message)
          })
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    // 通过
    agreeOk() {
      this.loading = true
      this.form.assetsData = this.formData
      formInfoApi
        .agree(this.form)
        .then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.updateVisible(false)
            this.$emit('done')
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
        .catch(() => { })
    },
    rejectOk() {
      this.visibleReject = false
      formInfoApi
        .rejeck(this.form)
        .then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.updateVisible(false)
            this.$emit('done')
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          // this.loading = false
          this.$message.error(e.message)
        })
        .catch(() => { })
    },
    // formnodes
    getFormNodes() {
      formInfoApi
        .formNode({ formId: this.form.id, limit: 1000, page: 1 })
        .then((res) => {
          // this.loading = false
          if (res.code === 0) {
            this.formNodeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          // this.loading = false
          this.$message.error(e.message)
        })
        .catch(() => { })
    },
    getFlowNodes() {
      formInfoApi
        .flowNode({ flowId: this.form.flowId })
        .then((res) => {
          // this.loading = false
          if (res.code === 0) {
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          // this.loading = false
          this.$message.error(e.message)
        })
        .catch(() => { })
    },
    setFormData(items) {
      this.formData = items
      this.reload()
    },
    remove(index) {
      this.formData.splice(index, 1)
      this.reload()
    }
  }
}
</script>

<style scoped lang='less'>
.ud-form-detail {
  :deep(.ant-descriptions-title) {
    padding-top: 10px;
  }

  :deep(.disabledColor) {
    background: #fff;
    opacity: 1;

    textarea[disabled] {
      color: black;
      cursor: text;
    }
  }
}
.ant-col-sty {
  max-height: 230px;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 10px;
}
</style>
